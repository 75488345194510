import React from "react";
import moment from "moment";
import DatePicker from "react-date-picker";
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import { useParams,  useNavigate } from 'react-router-dom';
import { Container } from "react-bootstrap";

function formatDate(date) {

  var dd = date.getDate();
  if (dd < 10) dd = '0' + dd;

  var mm = date.getMonth() + 1;
  if (mm < 10) mm = '0' + mm;

  var yy = date.getFullYear() % 100;
  if (yy < 10) yy = '0' + yy;

  return dd + '.' + mm + '.' + yy;
}

function formatDateSQL(date) {

  var dd = date._d.getDate();
  if (dd < 10) dd = '0' + dd;

  var mm = date._d.getMonth() + 1;
  if (mm < 10) mm = '0' + mm;

  var yy = date._d.getFullYear() % 100;
  if (yy < 10) yy = '0' + yy;

  return "" + yy + '.' + mm + '.' + dd + ' ' + date._d.getHours() + ':' + date._d.getMinutes() + ':' + date._d.getSeconds();

}

export class JournalC extends React.Component {
  constructor(props) {
    super(props);
    var dayFrom = new Date();
    var dayTo = new Date();

    this.state = {
      sdayOfWek: props.dayOfWek,
      visitStage: 0,
      selectKlient: null,
      idKlient: null,
      selectProducts: [],
      dayFrom: dayFrom,
      dayTo: dayTo,
      isNew: false,
      remoteOrders: [],
      orde: {}
    };
    this.setdayOfWek = this.setdayOfWek.bind(this);
    this.deleteOrde = this.deleteOrde.bind(this);
    this.backVisit = this.backVisit.bind(this);
    this.saveVisit = this.saveVisit.bind(this);
    this.handleDateFrom = this.handleDateFrom.bind(this);
    this.handleDateTo = this.handleDateTo.bind(this);
    this.goDebet = this.goDebet.bind(this);
    this.getRemoteOrders = this.getRemoteOrders.bind(this);

  }

  getRemoteOrders(d1, d2) {
    ///////////Журнал с сервера/////////////
    var url = localStorage.getItem("url") || "";
    var skey = localStorage.getItem("skey") || "";
    this.setState({ remoteOrders: [] });

    var xhr2 = new XMLHttpRequest();
    xhr2.open("POST", url + "mt-api.php", true);
    xhr2.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
    xhr2.onload = function (e) {
      if (xhr2.readyState === 4) {
        if (xhr2.status === 200) {
          let jsn = JSON.parse(xhr2.responseText);
          this.setState({ remoteOrders: jsn });
        } else {
          console.error(xhr2.statusText);
        }
      }
    }.bind(this);

    xhr2.onerror = function (e) {
      console.error(xhr2.statusText);
    }.bind(this);
    var df = moment(Date.parse(d1)).startOf('day');
    var dt = moment(Date.parse(d2)).endOf('day');

    xhr2.send((this.props.klientId != 'all' ? "klientId=" + this.props.klientId + "&" : "") + "id=4&d1=" + formatDateSQL(df) + "&d2=" + formatDateSQL(dt) + "&key=" + skey);
    ///////////debet/////////////

  }

  handleDateFrom(value) {
    this.setState({ dayFrom: value });
    this.getRemoteOrders(value, this.state.dayTo);
  }
  handleDateTo(value) {
    this.setState({ dayTo: value });
    this.getRemoteOrders(this.state.dayFrom, value);
  }

  setdayOfWek(val) {
    this.setState({ sdayOfWek: val.target.value });
  }



  deleteOrde(val) {
    var url = localStorage.getItem("url") || "";
    var skey = localStorage.getItem("skey") || "";

    var xhr2 = new XMLHttpRequest();
    xhr2.open("POST", url + "mt-api.php", true);
    xhr2.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
    xhr2.onload = function (e) {
      if (xhr2.readyState === 4) {
        if (xhr2.status === 200) {
          this.getRemoteOrders(this.state.dayFrom, this.state.dayTo);
        } else {
          console.error(xhr2.statusText);
        }
      }
    }.bind(this);

    xhr2.onerror = function (e) {
      console.error(xhr2.statusText);
    }.bind(this);

    xhr2.send("kod=" + val + "&id=5&key=" + skey);
  }

  draftOrde(val) {
    var url = localStorage.getItem("url") || "";
    var skey = localStorage.getItem("skey") || "";

    var xhr2 = new XMLHttpRequest();
    xhr2.open("POST", url + "mt-api.php", true);
    xhr2.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
    xhr2.onload = function (e) {
      if (xhr2.readyState === 4) {
        if (xhr2.status === 200) {
          this.getRemoteOrders(this.state.dayFrom, this.state.dayTo);
        } else {
          console.error(xhr2.statusText);
        }
      }
    }.bind(this);

    xhr2.onerror = function (e) {
      console.error(xhr2.statusText);
    }.bind(this);

    xhr2.send("kod=" + val + "&id=6&key=" + skey);
  }

  okOrde(val) {
    var url = localStorage.getItem("url") || "";
    var skey = localStorage.getItem("skey") || "";

    var xhr2 = new XMLHttpRequest();
    xhr2.open("POST", url + "mt-api.php", true);
    xhr2.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
    xhr2.onload = function (e) {
      if (xhr2.readyState === 4) {
        if (xhr2.status === 200) {
          this.getRemoteOrders(this.state.dayFrom, this.state.dayTo);
        } else {
          console.error(xhr2.statusText);
        }
      }
    }.bind(this);

    xhr2.onerror = function (e) {
      console.error(xhr2.statusText);
    }.bind(this);

    xhr2.send("kod=" + val + "&id=7&key=" + skey);
  }

  goDebet(val) {
    var idKlient = val.klientId;
    this.setState({ idKlient: idKlient, visitStage: 2 });
  }

  backVisit() {
    this.setState({ visitStage: 0 });
  }

  saveVisit() {
    var idOrder = Number(this.state.orde.id);
    var lastOrder = Number(localStorage.getItem("lastOrder")) || 0;
    var d = this.state.orde.dt;

    var sum = 0;
    var orde = {
      id: idOrder,
      dt: d,
      sync: false,
      klientId: this.state.selectKlient.id,
      klientName: this.state.selectKlient.name,
      withdrawal: this.state.selectKlient.withdrawal,
      komment: this.state.selectKlient.komment,
      blokCheck: this.state.selectKlient.blokCheck,
      products: []
    }
    this.props.products.forEach(val => {
      if (parseFloat(val.orde) !== 0 || parseFloat(val.ost) !== 0) {
        orde.products.push({ id: val.id, orde: val.orde, ost: val.ost });
        sum += val.orde * val.price;
      }
    })
    orde.sum = sum;
    var orders = JSON.parse(localStorage.getItem("orders")) || [];
    if (this.state.isNew) {
      orde.id = lastOrder + 1;
      orde.dt = Date.now();
      orders.push(orde);
    }
    else {
      for (let index = 0; index < orders.length; index++) {
        if (orders[index].id == idOrder) {
          orders[index] = orde;
        }
      }
    }
    localStorage.setItem("orders", JSON.stringify(orders));
    localStorage.setItem("status", 1);
    this.setState({ visitStage: 0 });
  }


  componentDidMount() {

    this.getRemoteOrders(this.state.dayFrom, this.state.dayTo);
  }

  render() {

    var rows = [];
    var tr = false;
    var cls;
    var orders = JSON.parse(localStorage.getItem("orders")) || [];
    //var orders = this.props.orders;
    orders.forEach(val => {
      var df = moment(Date.parse(this.state.dayFrom)).startOf('day');
      var dt = moment(Date.parse(this.state.dayTo)).endOf('day');
      val.isRemote = false;
      if (val.dt >= df && val.dt <= dt) {

        if (tr) {
          cls = "row tab-2r ";
        } else {
          cls = "row ";
        }
        tr = !tr;
        rows.push(
          <div className={cls} key={`rl-${val.id}`}>
            <div className="col-2">{formatDate(new Date(val.dt))}</div>
            <div className="col-6">{val.klientName}</div>
            <div className="col-2">{parseFloat(val.sum).toFixed(2)}</div>
            <div className="col-2">
              <Btnn val={val} />
            </div>
          </div>

        );
      }
    });

    var rOrders = [];
    try {
      rOrders = this.state.remoteOrders || [];
    } catch (error) {
      rOrders = [];
    }

    rOrders.forEach(val => {
      cls = "";
      if (val.isDraft) cls = "isDraft ";
      if (val.isDelete) cls = "isDelete ";
      if (val.isCheck) cls = "isCheck ";
      if (tr) {
        cls += "row tab-2r ";
      } else {
        cls += "row ";
      }
      tr = !tr;

      rows.push(
        <div className={cls} key={`rl-${val.id}`}>
          <div className="col-2">{formatDate(new Date(val.dt))}</div>
          <div className="col-6">{val.klientName}</div>
          <div className="col-2">
          <div>
              {parseFloat(val.sum).toFixed(2)} ₴
            </div>
            <div>
              {parseFloat(val.wsum).toFixed(2)} кг
            </div>
          </div>
          <div className="col-2">
            <Btnn
              val={val}
              makeDelete={() => { this.deleteOrde(val.id) }}
              makeDraft={() => { this.draftOrde(val.id) }}
              makeOk={() => { this.okOrde(val.id) }} />
          </div>
        </div>

      );
    })

    //const [value, onChange] = useState(new Date());
    return (
      <div className="titl">
        <div className="day-of-weak">
          <label>Журнал документов</label>
        </div>
        <div className="day-of-weak border-bottom">
          <label>C</label>
          <DatePicker name="dayFrom" onChange={this.handleDateFrom} value={this.state.dayFrom} />
          <label> по </label>
          <DatePicker name="dayTo" onChange={this.handleDateTo} value={this.state.dayTo} />
        </div>
        <div className="list-klient">
          {rows}

        </div>
      </div>

    );

  }
}

function Btnn({ val, makeDelete, makeDraft, makeOk }) {
  let navigate = useNavigate();
  return (<DropdownButton
    key={`dropdown-button-${val.id}`}
    id={`dropdown-button-${val.id}`}
    title="  "
  >
    <Dropdown.Item onClick={() => { navigate(localStorage.getItem("lp") + "Order/" + val.klientId + "/" + val.id); }}>Просмотр</Dropdown.Item>
    <Dropdown.Item onClick={() => { navigate(localStorage.getItem("lp") + "Debet/" + val.klientId); }}>Дебеторка</Dropdown.Item>
    <Dropdown.Item  onClick={() => { makeOk() }}>Статус - в работу</Dropdown.Item>
    <Dropdown.Item className="isDraft" onClick={() => { makeDraft() }}>Статус - ченовик</Dropdown.Item>
    <Dropdown.Item className="isDelete" onClick={() => { makeDelete() }}>Статус - удалить</Dropdown.Item>
  </DropdownButton>);
}

function Journal() {
  const { klientId } = useParams();
  let dbname = localStorage.getItem('dbname');
  const navigate = useNavigate();

  return <Container>
    <div className="g-top-info">
      <div className="top-info">
        <label>{dbname}</label>
        <img className="img-list-klient-orde" src={localStorage.getItem("lpi") +'btn-exit.png'} alt='' onClick={() => navigate(-1)} />
      </div>
    </div>
    <JournalC klientId={typeof klientId !== 'undefined' ? klientId : 'all'} />
  </Container>;
}
export default Journal;