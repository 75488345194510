import React from "react";
import OrderBlock from "./Components/OrderBlock";
import { Container, Dropdown, DropdownButton } from "react-bootstrap";
import Debet from "./Debet";
import Journal from "./Journal";
import { Link, Navigate, useNavigate } from "react-router-dom";

class TTCategoryRow extends React.Component {
  render() {
    const category = this.props.val.name;
    const idGroup = this.props.idGroup;
    var gimg;
    if (this.props.isGlobal) {
      gimg = <img src={localStorage.getItem("lpi") + "btn-open-folder.png"} alt="open-folder"></img>;
    } else {
      gimg = <img src={localStorage.getItem("lpi") + "btn-close-folder.png"} alt="close-folder"></img>;
    }

    return (
      <div className="row order-line order-group" onClick={() => { this.props.groupChange(idGroup) }}>
        <div className="col-12 strong">
          {gimg}
          {category}
        </div>
      </div>);
  }
}

function TTRow({ val, cls }) {

  let navigate = useNavigate();
  return (
    <div className={cls} key={val.id}>
      <div className="col-8">{val.name}</div>
      <div className="col-2">{parseFloat(val.dolg).toFixed(2)}</div>
      <div className="col-2">
        <DropdownButton
          key={`dropdown-button-${val.id}`}
          id={`dropdown-button-${val.id}`}
          title=" * "
        >
          <Dropdown.Item onClick={() => { navigate(localStorage.getItem("lp") + "Order/" + val.id); }}>Заказ</Dropdown.Item>
          <Dropdown.Item onClick={() => { navigate(localStorage.getItem("lp") + "Debet/" + val.id); }}>Дебеторка</Dropdown.Item>
          <Dropdown.Item onClick={() => { navigate(localStorage.getItem("lp") + "Journal/" + val.id); }}>Журнал</Dropdown.Item>
        </DropdownButton>

      </div>
    </div>);

}


export class ListTT extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      stage: -1,
      tt: [],
      products: [],
      group: "0",
      globalGroup: "0",
      idKlient: 0,
      textSearch: ""

    };
    this.goOrde = this.goOrde.bind(this);
    this.goDebet = this.goDebet.bind(this);
    this.goJurnal = this.goJurnal.bind(this);
    this.backVisit = this.backVisit.bind(this);
    this.saveVisit = this.saveVisit.bind(this);
    this.handleGroupChange = this.handleGroupChange.bind(this);

  }

  handleGroupChange(value) {
    this.setState({ group: value });
  }

  goOrde(id) {

    this.setState({ idKlient: id, stage: 1 });
  }

  goDebet(id) {

    this.setState({ idKlient: id, stage: 2 });
  }

  goJurnal(id) {
    this.setState({ idKlient: id, stage: 3 });
  }

  backVisit() {
    this.setState({ stage: 0 });
  }

  saveVisit() {
    var d = Date.now();
    var sum = 0;
    var orde = {
      dt: d,
      sync: false,
      klientId: this.state.selectKlient.id,
      klientName: this.state.selectKlient.name,
      withdrawal: this.state.selectKlient.withdrawal,
      komment: this.state.selectKlient.komment,
      blokCheck: this.state.selectKlient.blokCheck,
      products: []
    }
    this.props.products.forEach(val => {
      if (val.orde !== 0 || val.ost !== 0) {
        orde.products.push({ id: val.id, orde: val.orde, ost: val.ost });
        sum += val.orde * val.price;
      }
    })
    orde.sum = sum;
    var orders = JSON.parse(localStorage.getItem("orders")) || [];

    orders.push(orde);
    localStorage.setItem("status", 1);
    localStorage.setItem("orders", JSON.stringify(orders));
    this.setState({ stage: 0 });
  }

  componentDidMount() {
    var url = localStorage.getItem("url") || "";
    var skey = localStorage.getItem("skey") || "";
    this.setState({ stage: -1, syncTov: true, syncKlient: true });
    var xhr = new XMLHttpRequest();
    xhr.open("POST", url + "mt-api.php", true);
    xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
    xhr.onload = function (e) {
      if (xhr.readyState === 4) {
        if (xhr.status === 200) {
          localStorage.setItem("KLIENT", xhr.responseText);

          let jsn = JSON.parse(xhr.responseText);
          this.setState({ stage: 0, tt: jsn });

        } else {
          this.setState({ stage: 0 });
          console.error(xhr.statusText);
        }
      }
    }.bind(this);

    xhr.onerror = function (e) {
      console.error(xhr.statusText);
      this.setState({ stage: 0 });
    }.bind(this);
    xhr.send("id=1&key=" + skey);

  }



  render() {
    var skey = localStorage.getItem("skey") || false;
    if (!skey) { return (<Navigate to={localStorage.getItem("lp") + "Login"} replace={true} />); }

    switch (this.state.stage) {
      case -1:
        return <div>Loading...</div>;;
      case 1:
        return (React.createElement(OrderBlock, {
          klientId: this.state.idKlient,
          backVisit: this.backVisit
        }));

      case 2:
        return (React.createElement(Debet, {
          idKlient: this.state.idKlient,
          setStage: this.backVisit
        }));
      case 3:
        return (React.createElement(Journal, { setStage: this.backVisit, }));

      default:

        var rows = [];
        var rowsGG = [];
        var rowsG = [];
        var list = this.state.tt;
        if (this.state.textSearch != "") {
          list.forEach(val => {
            if (val.isCategory == 1) { return }
            if (String(val.name).toUpperCase().indexOf(this.state.textSearch.toUpperCase()) >= 0) {
              rows.push(
                React.createElement(TTRow, {
                  key: val.id,
                  cls: "row",
                  val: val,
                  goOrde: this.goOrde,
                  goDebet: this.goDebet,
                  goJurnal: this.goJurnal
                }));
            }
          })
        } else {
          list.forEach(val => {
            if (this.state.group != val.idGroup && this.state.group != val.id) { return }
            if (this.state.globalGroup == val.id) { return }
            if (val.isCategory == 1 && this.state.group == val.id) {
              rowsGG.push(
                React.createElement(TTCategoryRow, {
                  key: val.id,
                  val: val,
                  idGroup: val.id == this.state.group ? val.idGroup : val.id,
                  isGlobal: val.id == this.state.group ? true : false,
                  groupChange: this.handleGroupChange
                }));
            } else if (val.isCategory == 1) {

              rowsG.push(
                React.createElement(TTCategoryRow, {
                  key: val.id,
                  val: val,
                  idGroup: val.id == this.state.group ? val.idGroup : val.id,
                  isGlobal: val.id == this.state.group ? true : false,
                  groupChange: this.handleGroupChange
                }));

            } else {

              rows.push(
                React.createElement(TTRow, {
                  key: val.id,
                  cls: "row",
                  val: val,
                  goOrde: this.goOrde,
                  goDebet: this.goDebet,
                  goJurnal: this.goJurnal
                }));
            }
          })
        }
        rowsG.sort((a, b) => (a.props.val.name > b.props.val.name) ? 1 : -1)
        rows.sort((a, b) => (a.props.val.name > b.props.val.name) ? 1 : -1)

        return (
          <Container>
            <div className="titl">
              <div className="g-top-info">
                <div className="top-info">
                  <label>Список торговых точек</label>
                  <Link title="Выход" to={localStorage.getItem("lp")}>
                    <img className="img-list-klient-orde" src={localStorage.getItem("lpi") + "btn-exit.png"} alt='' /></Link>
                </div>
              </div>
              <div className="day-of-weak border-bottom">
                <label>Поиск:</label>
                <input
                  className="w-100"
                  type='text'
                  name='textSearch'
                  id='textSearch'
                  value={this.state.textSearch}
                  onChange={(event) => { this.setState({ textSearch: event.target.value }) }}
                />
              </div>
              <div className="list-klient">
                {rowsGG}
                {rowsG}
                {rows}
              </div>
            </div>
          </Container>
        );
    }
  }
}

export default ListTT;