import React from 'react';
import { Container } from 'react-bootstrap';
import { useNavigate } from "react-router-dom";



function Help() {
    let dbname = localStorage.getItem('dbname');
    const navigate = useNavigate();

    return (<Container style={{height:  '100vh'}}>
        <div className="g-top-info">
            <div className="top-info">
                <label>{dbname}</label>
                <img className="img-list-klient-orde" src={localStorage.getItem("lpi") + 'btn-exit.png'} alt='' onClick={() => navigate(-1)} />
            </div>
        </div>
        <video style={{height:  '100%', width: '100%'}} src={localStorage.getItem("lpi") + '1.mp4'} autoplay='true' controls/>
    </Container>);
}

export default Help;