import React, { Component } from 'react';
import { Container } from 'react-bootstrap';
import { Navigate, useNavigate, useParams } from "react-router-dom";
function formatDate(date) {

    var dd = date.getDate();
    if (dd < 10) dd = '0' + dd;

    var mm = date.getMonth() + 1;
    if (mm < 10) mm = '0' + mm;

    var yy = date.getFullYear() % 100;
    if (yy < 10) yy = '0' + yy;

    return dd + '.' + mm + '.' + yy;
}


class DebetZurC extends Component {

    constructor(props) {
        super(props);

        this.state = {
            stage: -1,
            debet: [],
        };

    }
    componentDidMount() {
        var url = localStorage.getItem("url") || "";
        var skey = localStorage.getItem("skey") || "";
        this.setState({ stage: -1 });
        var xhr = new XMLHttpRequest();
        xhr.open("POST", url + "mt-api.php", true);
        xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
        xhr.onload = function (e) {
            if (xhr.readyState === 4) {
                if (xhr.status === 200) {
                    //localStorage.setItem("KLIENT", xhr.responseText);
                    let jsn = [];
                    try {
                        jsn = JSON.parse(xhr.responseText);
                    } catch (error) {

                    }
                    this.setState({ stage: 0, debet: jsn });
                } else {
                    this.setState({ stage: 0 });
                    console.error(xhr.statusText);
                }
            }
        }.bind(this);

        xhr.onerror = function (e) {
            console.error(xhr.statusText);
            this.setState({ stage: 0 });
        }.bind(this);
        xhr.send("idkl=" + this.props.klientId + "&id=3&key=" + skey);


    }


    render() {
        var skey = localStorage.getItem("skey") || false;
        if (!skey) { return (<Navigate to="/Login" replace={true} />); }
        switch (this.state.stage) {
            case -1:
                return <div>Loading...</div>;;
            default:
                const rows = [];
                var orders = this.state.debet || [];
                var tr = false;
                var cls;
                orders.forEach(val => {
                    if (tr) {
                        cls = "row tab-2r";
                    } else {
                        cls = "row";
                    }
                    tr = !tr;
                    let dolg = parseFloat(val.dolg).toFixed(2);
                    let pt = parseFloat(val.pt).toFixed(2);
                    let rt = parseFloat(val.rt).toFixed(2);
                    rows.push(
                        <div className={cls} key={`rl-${val.id}`}>
                            <div className="col-5">
                                <div>{formatDate(new Date(val.d))} </div>
                                <div>{val.dok} {val.nom}</div>
                                <div>{val.nn}</div>
                            </div>
                            <div className="col-2">{pt}</div>
                            <div className="col-2">{rt}</div>
                            <div className={dolg > 0 ? "col-2 text-danger" : "col-2 text-success"}>{dolg}</div>
                        </div>
                    );
                });

                return (
                    <div className="titl">
                        <div className="set-row">
                            <label>Журнал взаиморасчетов {}</label>
                        </div>
                        <div className="list-debet">
                            <div className="row bg-light bb-1 bt-1" key={`rl-00`}>
                                <div className="col-5">
                                    <div>Дата </div>
                                    <div>Документ</div>
                                </div>
                                <div className="col-2">Приход</div>
                                <div className="col-2">Расход</div>
                                <div className="col-2" >Сальдо</div>
                            </div>
                            {rows}
                        </div>

                    </div>

                );
        }
    }
}

function DebetZur() {
    let dbname = localStorage.getItem('dbname');
    const navigate = useNavigate();
    const { klientId } = useParams();

    return (<Container>
        <div className="g-top-info">
            <div className="top-info">
                <label>{dbname}</label>
                <img className="img-list-klient-orde" src={localStorage.getItem("lpi") + 'btn-exit.png'} alt='' onClick={() => navigate(-1)} />
            </div>
        </div>
        <DebetZurC klientId={klientId} />
    </Container>);
}

export default DebetZur;