import React from "react";

export class BlockBtn extends React.Component {
  render() {
    return (
      <div className="container">
        <div className="row">
          <div className="col btn-top">
            <img src={localStorage.getItem("lpi") + "btn-note.png"} alt="Детали заказа" onClick={() => { this.props.noteShow() }} />
          </div>
          <div className={this.props.isSearch ? 'col btn-top bg-warning' : 'col btn-top'}>
            <img src={localStorage.getItem("lpi") + "btn-filtr.png"} alt="Фильтр" onClick={() => { this.props.filtrShow() }} />
          </div>

          <div className="col btn-top">
            <img src={localStorage.getItem("lpi") + "btn-save.png"} alt="Сохранить как черновик" onClick={() => { if (this.props.isCheck) { alert('Заказ обработан оператором!') } else { this.props.saveVisit(-1) } }} />
          </div>

          <div className="col btn-top">
            <img src={localStorage.getItem("lpi") + "btn-save-ok.png"} alt="Сохранить готовый заказ" onClick={() => { if (this.props.isCheck) { alert('Заказ обработан оператором!') } else { this.props.saveVisit(0) } }} />
          </div>

        </div>
      </div>
    );
  }
}

export default BlockBtn;