import React from "react";
import ModalNote from "./ModalNote";
import ModalFiltr from "./ModalFiltr";
import ModalOrde from "./ModalOrde";
import BlockInfo from "./BlockInfo";
import BlockBtn from "./BlockBtn";
import ProductTable from "./ProductTable";
import { useParams, Navigate } from 'react-router-dom';
import { Container } from "react-bootstrap";
import { useNavigate } from 'react-router-dom';

function kod_gen(len) {
  let chrs = 'abdehkmnpswxzABDEFGHKMNPQRSTWXZ123456789';
  var str = '';
  for (var i = 0; i < len; i++) {
    var pos = Math.floor(Math.random() * chrs.length);
    str += chrs.substring(pos, pos + 1);
  }
  return str;
}

function formatDateSQL(date) {

  var dd = date.getDate();
  if (dd < 10) dd = '0' + dd;

  var mm = date.getMonth() + 1;
  if (mm < 10) mm = '0' + mm;

  var yy = date.getFullYear() % 100;
  if (yy < 10) yy = '0' + yy;

  return "" + yy + '.' + mm + '.' + dd + ' ' + date.getHours() + ':' + date.getMinutes() + ':' + date.getSeconds();

}

export class OrderBlockC extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      orderOnly: false,
      restOnly: false,
      searchText: "",
      selectKlient: {},
      product: [],
      selectProduct: { name: "", price: 0, ost: 0, orde: 0, weight: 0, id: 0 },
      isOrde: false,
      isNote: false,
      isSearch: false,
      isFiltr: false,
      isDebet: false,
      stage: -1,
      save: 1
    };

    this.handleCancel = this.handleCancel.bind(this);
    this.handleOrde = this.handleOrde.bind(this);
    this.handleSaveOrde = this.handleSaveOrde.bind(this);
    this.noteShow = this.noteShow.bind(this);
    this.noteClose = this.noteClose.bind(this);
    this.debetClose = this.debetClose.bind(this);
    this.filtrShow = this.filtrShow.bind(this);
    this.filtrClose = this.filtrClose.bind(this);
    this.saveVisit = this.saveVisit.bind(this);
  }


  handleCancel() {
    this.setState({ isOrde: false });
  }

  handleSaveOrde(selProduct) {
    var orde = this.state.product;
    orde.forEach(product => {
      if (product.id === selProduct.id) {
        product.ost = selProduct.ost;
        product.orde = selProduct.orde;
      }
    })

    var isSearch = !this.state.orderOnly && !this.state.restOnly ? false : true;

    this.setState({ isOrde: false, product: orde, searchText: "", isSearch: isSearch });
  }

  handleOrde(selProd) {
    this.setState({
      isOrde: true,
      selectProduct: selProd
    });
  }

  noteShow() {
    this.setState({ isNote: true });
  }

  noteClose(val) {

    this.setState({ isNote: false, selectKlient: val });

  }

  debetClose() {

    this.setState({ isDebet: false });

  }

  filtrShow() {
    this.setState({ isFiltr: true });
  }

  filtrClose(val, valSearch) {
    var pv = { isFiltr: false, searchText: valSearch };
    var isSearch = valSearch == "" ? false : true;
    val.forEach(v => {
      pv[v.id] = v.val;
      isSearch = v.val ? true : isSearch;
    });

    pv.isSearch = isSearch;
    this.setState(pv);

  }

  getSuma() {
    let suma = 0;
    this.state.product.forEach(product => {
      if (product.isCategory == 0) {
        suma += parseFloat(product.price) * parseFloat(product.k) * parseFloat(product.orde);
      }
    });
    return suma;
  }

  getWeight() {
    let suma = 0;
    this.state.product.forEach(product => {
      if (product.isCategory == 0) {
        suma += parseFloat(product.weight) * parseFloat(product.k) * parseFloat(product.orde);
      }
    });
    return suma;
  }

  componentDidMount() {



    var url = localStorage.getItem("url") || "";
    var skey = localStorage.getItem("skey") || "";
    this.setState({ stage: -1 });

    var xhr1 = new XMLHttpRequest();
    xhr1.open("POST", url + "mt-api.php", true);
    xhr1.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
    xhr1.onload = function (e) {
      if (xhr1.readyState === 4) {
        if (xhr1.status === 200) {

          let jsn = JSON.parse(xhr1.responseText);
          this.setState({ stage: 0, product: jsn.tov, selectKlient: jsn.sh });
        } else {
          this.setState({ stage: 0 });
          console.error(xhr1.statusText);
        }
      }
    }.bind(this);

    xhr1.onerror = function (e) {
      console.error(xhr1.statusText);
      this.setState({ stage: 0 });
    }.bind(this);
    xhr1.send((this.props.orderId != "new" ? "orderId=" + this.props.orderId + "&" : "") + "idkl=" + this.props.klientId + "&id=2&key=" + skey);

  }

  saveVisit(status_order) {

    var d = Date.now();
    var sum = 0;
    var wsum = 0;
    var orde = {
      id: kod_gen(24),
      dt: d,
      orderId: this.props.orderId,
      pr: status_order,
      klientId: this.state.selectKlient.id,
      klientName: this.state.selectKlient.name,
      withdrawal: this.state.selectKlient.withdrawal,
      komment: this.state.selectKlient.komment,
      blokCheck: this.state.selectKlient.blokCheck,
      products: []
    }
    this.state.product.forEach(val => {
      if (parseFloat(val.orde) !== 0 || parseFloat(val.ost) !== 0) {
        orde.products.push({ id: val.id, orde: val.orde, ost: val.ost });
        sum += val.orde * val.price;
        wsum += val.orde * val.weight;
      }
    })
    orde.sum = sum;
    orde.wsum = wsum;
    var orders = JSON.parse(localStorage.getItem("orders")) || [];

    orders.push(orde);
    localStorage.setItem("orders", JSON.stringify(orders));

    ///////////upload////////////////

    var url = localStorage.getItem("url") || "";
    var skey = localStorage.getItem("skey") || "";

    orders = JSON.parse(localStorage.getItem("orders")) || [];
    var sendOrde = [];
    orders.forEach(val => {
      if (!val.sync) {
        val.dtsql = formatDateSQL(new Date(parseInt(val.dt)));
        sendOrde.push(val);
      }
    });
    if (sendOrde.length > 0) {
      var xhr3 = new XMLHttpRequest();
      xhr3.open("POST", url + "mt-api-in.php", true);
      xhr3.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded;');
      xhr3.onload = function (e) {
        if (xhr3.readyState === 4) {
          if (xhr3.status === 200) {
            localStorage.removeItem("orders");
            console.log(xhr3.responseText);
            this.setState({ stage: -2 });

          } else {
            this.setState({ stage: 0 });
            console.error(xhr3.statusText);
          }
        }
      }.bind(this);

      xhr3.onerror = function (e) {
        this.setState({ stage: 0 });
        console.error(xhr3.statusText);
      }.bind(this);

      var sendOrders = {};
      sendOrders.key = "sdfg45mnf;bdaj049gans;";
      sendOrders.part = "orde";
      sendOrders.body = sendOrde;
      sendOrders.skey = skey;
      console.dir(sendOrders);
      xhr3.send(JSON.stringify(sendOrders));
    } else {
      this.setState({ stage: 0 });
    }
    /////////////////////////////////

    this.setState({ stage: -1 });


  }

  render() {

    if (this.state.stage === -2) {
      return <Navigate to={this.props.orderId == 'new' ? localStorage.getItem("lp") + "Order" : localStorage.getItem("lp") + "Journal"} replace={true} />;
    }
    else if (this.state.stage === -1) {
      return <div>Loading...</div>;
    } else {
      let gg=localStorage.getItem('globalGroup');
      return (
        React.createElement("div", { className: "titl-order" },

          React.createElement(BlockBtn, {
            noteShow: this.noteShow,
            debetShow: this.debetShow,
            filtrShow: this.filtrShow,
            backVisit: this.props.backVisit,
            saveVisit: this.saveVisit,
            isSearch: this.state.isSearch,
            isCheck: this.state.selectKlient.isCheck,
            key: 0
          }),
          React.createElement("div", { className: "list-orde" },
            React.createElement(BlockInfo, {
              suma: this.getSuma(),
              weight: this.getWeight(),
              withdrawal: this.state.selectKlient.withdrawal,
              dolg: this.state.selectKlient.dolg,
              klient: this.state.selectKlient.name,
              key: 1
            }),
            React.createElement(ProductTable, {
              products: this.state.product,
              searchText: this.state.searchText,
              orderOnly: this.state.orderOnly,
              restOnly: this.state.restOnly,
              handleOrde: this.handleOrde,
              globalGroup: gg,
              key: 2
            })),
          React.createElement(ModalOrde, {
            isShow: this.state.isOrde,
            selectProduct: this.state.selectProduct,
            handleCancel: this.handleCancel,
            handleSaveOrde: this.handleSaveOrde,
            key: 3
          }),
          React.createElement(ModalNote, {
            isShow: this.state.isNote,
            selectKlient: this.state.selectKlient,
            noteClose: this.noteClose,
            key: 4
          }),
          React.createElement(ModalFiltr, {
            isShow: this.state.isFiltr,
            filtr: [

              { id: "orderOnly", name: "Показать заказ", val: this.state.orderOnly }
            ],
            filtrClose: this.filtrClose,
            key: 6
          })
        )
      );
    }
  }
}

function OrderBlock() {
  const { klientId, orderId } = useParams();
  let dbname = localStorage.getItem('dbname');
  const navigate = useNavigate();

  return <Container>
    <div className="g-top-info">
      <div className="top-info">
        <label>{dbname}</label>
        <img className="img-list-klient-orde" src={localStorage.getItem("lpi") + "btn-exit.png"} alt='' onClick={() => navigate(-1)} />
      </div>
    </div>
    <OrderBlockC klientId={klientId} orderId={typeof orderId !== 'undefined' ? orderId : 'new'} /></Container>;
}

export default OrderBlock;