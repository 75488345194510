import React from "react";

class ProductCategoryRow extends React.Component {
  render() {
    const category = this.props.category;
    const idGroup = this.props.idGroup;
    var gimg;
    if (this.props.isGlobal) {
      gimg = <img src={localStorage.getItem("lpi") + "btn-open-folder.png"} alt="open-folder"></img>;
    } else {
      gimg = <img src={localStorage.getItem("lpi") + "btn-close-folder.png"} alt="close-folder"></img>;
    }

    return (
      <div className="row order-line order-group" onClick={() => { this.props.groupChange(idGroup) }}>
        <div className="col-12 strong">
          {gimg}
          {category}
        </div>
      </div>);
  }
}

class ProductRow extends React.Component {
  render() {
    const product = this.props.product;
    
    return (
      React.createElement("a", { onClick: () => { this.props.handleOrde(product) } },
        React.createElement("div", { className: "row order-line" },
          React.createElement("div", { className: "col-7" }, product.name),
          React.createElement("div", { className: "col-3 order-line-n" },
            React.createElement("span", null, product.price)),
          React.createElement("div", { className: "col-2 order-line-n" },
            React.createElement("span", { className: "strong" }, product.orde)
          ))));
  }
}

export class ProductTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      group: props.globalGroup,
      globalGroup: props.globalGroup
    };

    this.handleGroupChange = this.handleGroupChange.bind(this);
  }

  handleGroupChange(value) {
    this.setState({ group: value });
  }

  render() {
    const rowsG = [];
    const rowsGG = [];
    const rows = [];
    if (this.props.searchText == "" && !this.props.orderOnly) {
      this.props.products.forEach(product => {
        if (this.state.group != product.idGroup && this.state.group != product.id) { return }
        if (this.state.globalGroup == product.id) { return }

        if (product.isCategory == 1 && this.state.group == product.id) {
          rowsGG.push(
            React.createElement(ProductCategoryRow, {
              category: product.name,
              key: product.id,
              idGroup: product.id == this.state.group ? product.idGroup : product.id,
              isGlobal: product.id == this.state.group ? true : false,
              groupChange: this.handleGroupChange
            }));
        } else if (product.isCategory == 1) {

          rowsG.push(
            React.createElement(ProductCategoryRow, {
              category: product.name,
              key: product.id,
              idGroup: product.id == (this.state.group) ? product.idGroup : product.id,
              isGlobal: product.id == (this.state.group) ? true : false,
              groupChange: this.handleGroupChange
            }));

        } else {

          if ((this.props.restOnly && parseFloat(product.ost_skl) > 0) || (!this.props.restOnly)) {
            
            rows.push(
              React.createElement(ProductRow, {
                product: product,
                handleOrde: this.props.handleOrde
                
              }));
          }
        }
      });

    } else if (this.props.searchText != "") {
      this.props.products.forEach(product => {
        const st = String(this.props.searchText).toUpperCase();
        if (product.isCategory == 0 && String(product.name).toUpperCase().indexOf(st) >= 0) {
         
          rows.push(
            React.createElement(ProductRow, {
              product: product,
              handleOrde: this.props.handleOrde
              
            }));
        }
      });
    } else if (this.props.orderOnly) {
      this.props.products.forEach(product => {
        if (product.isCategory == 0 && product.orde > 0) {
         
          rows.push(
            React.createElement(ProductRow, {
              product: product,
              handleOrde: this.props.handleOrde,
              idProduct: product.id,
              key: product.id,
              
            }));
        }
      });
    }
    rowsG.sort((a, b) => (a.props.category > b.props.category) ? 1 : -1)
    rows.sort((a, b) => (a.props.product > b.props.product) ? 1 : -1)

    return (<div className="container ordTable">
      {rowsGG}
      {rowsG}
      {rows}
    </div>);
  }
}

export default ProductTable;