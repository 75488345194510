import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import {
  BrowserRouter,
  Routes,
  Route
} from "react-router-dom";
import Debet from './Debet';
import DebetZur from './DebetZur';
import Main from './Main';
import Journal from './Journal';
import ListTT from './ListTT';
import Help from './Help';
import Login from './Components/Login';
import OrderBlock from './Components/OrderBlock';

function App() {
  localStorage.setItem("lp", "/");
  localStorage.setItem("lpi", "http://my.stimul.dp.ua/img/");
  console.log(window.location.pathname);
  console.log(window.location.pathname + "Order/:klientId");
  return (<BrowserRouter>
    <Routes>
      <Route path="/" element={<Main />} />
      <Route path="/Order" element={<ListTT />} />
      <Route path="/Order/:klientId" element={<OrderBlock />} />
      <Route path="/Order/:klientId/:orderId" element={<OrderBlock />} />

      <Route path="/Journal" element={<Journal />} />
      <Route path="/Journal/:klientId" element={<Journal />} />

      <Route path="/Debet" element={<Debet />} />
      <Route path="/Debet/:klientId" element={<DebetZur />} />
      <Route path="/Login" element={<Login />} />

      <Route path="/help" element={<Help />} />

    </Routes>
  </BrowserRouter>
  );
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
