import React from 'react';

class ModalNote extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectKlient: this.props.selectKlient
        };
        this.handleInputChange = this.handleInputChange.bind(this);
    }

    handleInputChange(event) {
        const target = event.target;
        const name = target.name;
        var value = target.type === 'checkbox' ? target.checked : target.value;
        if (target.type === 'number') { value = Number(value) }

        if (target.type === 'checkbox') {
            this.props.selectKlient.blokCheck.forEach(blokCheck => {
                if (blokCheck.id === name) {
                    blokCheck.val = value;
                }
            })
        } else {
            this.props.selectKlient[name] = value;
        }

        this.setState({
            selectKlient: this.props.selectKlient
        });



    }

    render() {
            if (this.props.isShow) {

                const rows = [];
                var i = 0;
                this.props.selectKlient.blokCheck.forEach(blokCheck => {
                    i++;
                    rows.push(
                        React.createElement("p", { key: "p" + i },
                            React.createElement("input", {
                                type: "checkbox",
                                name: blokCheck.id,
                                checked: blokCheck.val,
                                onChange: this.handleInputChange,
                                key: blokCheck.id
                            }),
                            ' ', blokCheck.name));

                });

                return (
                    React.createElement("div", { className: "modal m1" },
                        React.createElement("div", { className: "modal-dialog" },
                            React.createElement("div", { className: "modal-content" },
                                React.createElement("div", { className: "modal-header border-bottom" },
                                    React.createElement("p", { className: "order-info-klient" }, "Детали заказа")),
                                React.createElement("div", { className: "modal-body" },
                                    rows,
                                    React.createElement("div", { className: "tov-orde" },
                                        React.createElement("label", { htmlFor: "orde" }, "Комментарий"),

                                        React.createElement('input', {
                                            type: 'text',
                                            name: 'komment',
                                            id: 'komment',
                                            value: this.props.selectKlient.komment,
                                            onChange: this.handleInputChange
                                        })
                                    ),
                                    
                                ),

                                React.createElement("div", { className: "tov-btn" },
                                    React.createElement("button", { className: "bt1", onClick: () => { this.props.noteClose(this.props.selectKlient) } }, "Ok")
                                )
                            )
                        )
                    )
                );

            } else {
                return (
                    React.createElement("div", null, ""))
            }
        }
        //
        //React.createElement("input", { type: "number", id: "ost" }, this.state.ost)
}
export default ModalNote;