import React, { Component } from 'react';
import { Button, Col, Container, Row, } from 'react-bootstrap';
import { Link, Outlet, Navigate } from "react-router-dom";

class Main extends Component {
  constructor(props) {
    super(props);
    this.state = {
      status: -1,
    };
  }


  render() {
    var skey = localStorage.getItem("skey") || false;
    if (!skey) { return (<Navigate to={localStorage.getItem("lp") + "Login"} replace={true} />); }

    let name = localStorage.getItem("name") || "";
    let dbname = localStorage.getItem("dbname") || "";

    return (

      <div className='titl'>
        <Container>
          <Row className='p-5'>
            <div className='titl-info-block'>
              <table className='w-100'>
                <thead></thead>
                <tbody>
                  <tr>
                    <td className='br-1'>Имя</td>
                    <td>{name}</td>
                  </tr>
                  <tr className='bt-1 bb-1 '>
                    <td className='br-1'>База данных</td>
                    <td>{dbname}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </Row>
          <Row >
            <Col className='p-3' xs={6} md={4}>
              <Link title="Создать новый заказ" to={localStorage.getItem("lp") + "Order"}>  <Button className='butn w-100'>Заказ</Button></Link>
            </Col>
            <Col className='p-3' xs={6} md={4}>
              <Link title="Жернал заказов" to={localStorage.getItem("lp") + "Journal"}><Button className='butn w-100'>Журнал</Button></Link>
            </Col>
            <Col className='p-3' xs={6} md={4}>
            <Link title="Журнал неоплаченных накладных" to={localStorage.getItem("lp") + "Debet"}><Button className='butn w-100'>Задолженность</Button></Link>
            </Col>
            <Col className='p-3' xs={6} md={4}>
            <Link title="Журнал неоплаченных накладных" to={localStorage.getItem("lp") + "help"}><Button className='butn w-100'>Помощь</Button></Link>
            </Col>
            <Col className='p-3' xs={6} md={4}>
              <Button className='butn w-100' onClick={() => { localStorage.removeItem('skey'); this.setState({ status: 0 }) }}>Выход</Button>
            </Col>
            <div><Outlet /></div>
          </Row>
        </Container>

      </div>
    );

  }
}


export default Main;