import React from 'react';

class ModalOrde extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            orde: this.props.selectProduct.orde,
            weight: this.props.selectProduct.weight,
        };
        this.handleInputChange = this.handleInputChange.bind(this);
    }

    handleInputChange(event) {
        const target = event.target;
        var value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        if (target.type === 'number') { value = Number(value) }

        this.setState({
            [name]: value
        });
        this.props.selectProduct[name] = value;
    }

    render() {
        if (this.props.isShow) {
            /*                 if (this.state.orde != this.props.selectProduct.orde) {
                                this.setState({
                                    orde: this.props.selectProduct.orde
                                });
                            }
             */
            const selProd = this.props.selectProduct;
            let dop = "";
            if (selProd.k > 1) {
                dop = <div className="tov-prop">
                    <span>Количество в ящике</span>
                    <span>{"" + selProd.k + " " + selProd.edp}</span>
                </div>;
            }
            return (<div className="modal m1">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header border-bottom">
                            <p className="order-info-klient">
                                {selProd.name}
                            </p>
                        </div>
                        <div className="modal-body">
                            <div className="tov-prop">
                                <span>Цена({selProd.edp})</span>
                                <span>{selProd.price}</span>
                            </div>
                            <div className="tov-prop">
                                <span>Вес ящика</span>
                                <span>{"" + (selProd.weight * selProd.k).toFixed(2) + " кг"}</span>
                            </div>
                            {dop}
                            <div className="tov-orde">
                                <label htmlFor="orde">Заказ({selProd.ed})</label>
                                <input
                                    type='number'
                                    step="0.01"
                                    name='orde'
                                    id='orde'
                                    value={this.props.selectProduct.orde}
                                    autofocus='true'
                                    onChange={this.handleInputChange}
                                    onInput={function (evt) {
                                        let str = String(evt.target.value);
                                        if (str.length > 1 && str.indexOf('0') == 0 && (str.indexOf(',') != 1 || str.indexOf('.') != 1))
                                            evt.target.value = parseFloat(evt.target.value);
                                    }}
                                />
                            </div>
                            <div className="tov-btn">
                                <button className="bt1" onClick={() => { this.props.handleSaveOrde(selProd) }}>Ok</button>
                                <button className="bt1" onClick={() => { this.props.handleCancel() }}>Отмена</button>

                            </div>

                        </div>
                    </div>
                </div>

            </div>
            );

        } else {
            return (
                React.createElement("div", null, ""))
        }
    }

}
export default ModalOrde;