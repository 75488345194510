import React from "react";

export class ModalFiltr extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filtr: this.props.filtr,
      textSearch: ""
    };
    this.handleInputChange = this.handleInputChange.bind(this);
  }

  handleInputChange(event) {
    const target = event.target;
    const name = target.name;
    var value = target.type === 'checkbox' ? target.checked : target.value;
    if (target.type === 'number') { value = Number(value) }

    if (target.type === 'checkbox') {
      this.props.filtr.forEach(blokCheck => {
        if (blokCheck.id === name) {
          blokCheck.val = value;
        }
      })
      this.setState({
        filtr: this.props.filtr
      });
    } else {
      this.setState({
        textSearch: value
      });
    }


    // this.props.filtrClose(this.props.filtr)

  }

  render() {
    if (this.props.isShow) {

      const rows = [];
      var i = 0;
      this.props.filtr.forEach(blokCheck => {
        i++;
        rows.push(
          React.createElement("p", { key: "ps" + i },
            React.createElement("input", {
              type: "checkbox",
              name: blokCheck.id,
              checked: blokCheck.val,
              onChange: this.handleInputChange
            }),
            ' ', blokCheck.name));

      });

      return (
        React.createElement("div", { className: "modal m1" },
          React.createElement("div", { className: "modal-dialog" },
            React.createElement("div", { className: "modal-content" },
              React.createElement("div", { className: "modal-header border-bottom" },
                React.createElement("p", { className: "order-info-klient" }, "Фильтр")),
              React.createElement("div", { className: "modal-body" },
                rows,
                <hr></hr>,
                <label>Поиск по названию</label>,
                React.createElement('input', {
                  className: "w-100",
                  type: 'text',
                  name: 'textSearch',
                  id: 'textSearch',
                  value: this.state.textSearch,
                  onChange: this.handleInputChange
                }),
                React.createElement("div", { className: "tov-btn" },
                  React.createElement("button", {
                    className: "bt1", onClick: () => {
                      this.props.filtrClose(this.state.filtr, this.state.textSearch)
                    }
                  }, "Ok"),
                  React.createElement("button", {
                    className: "bt1", onClick: () => {
                      this.props.filtr.forEach(blokCheck => {
                        blokCheck.val = false;
                      });
                      this.setState({
                        textSearch: "",
                        filtr: this.props.filtr
                      });
                      this.props.filtrClose(this.props.filtr, "");
                    }
                  }, "Сброс")
                )
              )
            )
          )
        )
      );

    } else {
      return (
        React.createElement("div", null, ""))
    }
  }
  //
  //React.createElement("input", { type: "number", id: "ost" }, this.state.ost)
}

export default ModalFiltr;