import React, { Component } from 'react';
import { Container } from 'react-bootstrap';
import { Navigate, useNavigate } from "react-router-dom";

class DebetC extends Component {

    constructor(props) {
        super(props);

        this.state = {
            stage: -1,
            debet: [],
        };

    }
    componentDidMount() {
        var url = localStorage.getItem("url") || "";
        var skey = localStorage.getItem("skey") || "";
        this.setState({ stage: -1 });
        var xhr = new XMLHttpRequest();
        xhr.open("POST", url + "mt-api.php", true);
        xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
        xhr.onload = function (e) {
            if (xhr.readyState === 4) {
                if (xhr.status === 200) {
                    //localStorage.setItem("KLIENT", xhr.responseText);
                    let jsn = [];
                    try {
                        jsn = JSON.parse(xhr.responseText);
                    } catch (error) {
                        console.log(xhr.responseText);
                    }
                    this.setState({ stage: 0, debet: jsn });
                } else {
                    this.setState({ stage: 0 });
                    console.error(xhr.statusText);
                }
            }
        }.bind(this);

        xhr.onerror = function (e) {
            console.error(xhr.statusText);
            this.setState({ stage: 0 });
        }.bind(this);
        xhr.send("id=8&key=" + skey);

    }


    render() {
        var skey = localStorage.getItem("skey") || false;
        if (!skey) { return (<Navigate to="/Login" replace={true} />); }
        switch (this.state.stage) {
            case -1:
                return <div>Loading...</div>;;
            default:
                const rows = [];
                var orders = this.state.debet || [];
                var tr = false;
                var cls;
                orders.forEach(val_kl => {
                    if (tr) {
                        cls = "row tab-2r";
                    } else {
                        cls = "row";
                    }
                    tr = !tr;
                    let dolg = parseFloat(val_kl.dolg).toFixed(2);
                    rows.push(
                        <div className={"grkl " + cls} key={`rlk-${val_kl.kod}`}>
                            <div className="col-5">
                                <div>{val_kl.name} </div>
                            </div>
                            <div className="col-2"></div>
                            <div className="col-2"></div>
                            <div className={dolg > 0 ? "col-2 text-danger" : "col-2 text-success"}>{dolg}</div>
                        </div>
                    );
                    val_kl.docs.forEach(val => {
                        if (tr) {
                            cls = "row tab-2r";
                        } else {
                            cls = "row";
                        }
                        tr = !tr;
                        let dolg = parseFloat(val.dolg).toFixed(2);
                        let summ = parseFloat(val.summ).toFixed(2);
                        let dn = new Date();
                        let d = new Date(val.datapn);
                        var days = Math.ceil(Math.abs(dn.getTime() - d.getTime()) / (1000 * 3600 * 24));

                        rows.push(
                            <div className={cls} key={`rl-${val.id}`}>
                                <div className="col-5">
                                    <div>{val.document} </div>
                                    <div>{val.ots} дней</div>
                                </div>
                                <div className="col-2">{summ}</div>
                                <div className="col-2">{days}</div>
                                <div className={dolg > 0 ? "col-2 text-danger" : "col-2 text-success"}>{dolg}</div>
                            </div>
                        );
                    });
                });

                return (
                    <div className="titl">

                        <div className="set-row">
                            <label>Неоплаченные накладные</label>
                        </div>
                        <div className="list-debet">
                            <div className="row bg-light bb-1 bt-1" key={`rl-00`}>
                                <div className="col-5">
                                    <div>Контрагент/Документ </div>
                                    <div>Отсрочка док</div>
                                </div>
                                <div className="col-2">Сумма</div>
                                <div className="col-2">Дней просрочки</div>
                                <div className="col-2">Задолженность</div>
                            </div>
                            {rows}
                        </div>

                    </div>

                );
        }
    }
}

function Debet() {
    let dbname = localStorage.getItem('dbname');
    const navigate = useNavigate();

    return (<Container>
        <div className="g-top-info">
            <div className="top-info">
                <label>{dbname}</label>
                <img className="img-list-klient-orde" src={localStorage.getItem("lpi") + 'btn-exit.png'} alt='' onClick={() => navigate(-1)} />
            </div>
        </div>
        <DebetC />
    </Container>);
}

export default Debet;