import React from "react";
import { Container, Row,Col } from "react-bootstrap";

export class BlockInfo extends React.Component {
  render() {
    const suma = this.props.suma;
    const dolg = this.props.dolg;
    const klient = this.props.klient;
    const weight = this.props.weight;
    return (
      <Container className="block-info">
        <Row>
          <Col>
            <div className="order-info border-bottom">
              <span className="order-info-klient" >{klient}</span>
            </div>
            <div className="order-info ">
              <span>Сальдо</span>
              <span className={(parseFloat(dolg).toFixed(2) < 0) ? { className: "TextRed" } : null} >{parseFloat(dolg).toFixed(2)}</span>
            </div>
            <div className="order-info ">
              <span>Сумма заказа</span>
              <span >{parseFloat(suma).toFixed(2)} ₴</span>
            </div>
            <div className="order-info ">
              <span>Вес заказа</span>
              <span >{parseFloat(weight).toFixed(2)} кг</span>
            </div>
          </Col>
        </Row>
      </Container>);
  }
}

export default BlockInfo;
